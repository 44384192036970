import React, { useState } from "react";
import { Helmet } from "react-helmet";
import emailjs from 'emailjs-com';

const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Victor Springer",
    "url": "https://linkedin.com/in/victorspringer",
    "sameAs": [
        "https://github.com/victorspringer"
    ],
    "image": "https://victorspringer.com/avatar.jpg",
};

const Contact = () => {
    const [status, setStatus] = useState(null);
    const [isSending, setIsSending] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSending(true);
        setStatus(null);

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        const serviceID = "service_50gidph";
        const templateID = "template_s1zcbdr";
        const userID = "Fb9OaGAyhk7ZahykS";

        const emailData = {
            name: data.name,
            email: data.email,
            subject: "New message from " + data.name,
            message: data.message,
        };

        try {
            const response = await emailjs.send(serviceID, templateID, emailData, userID);

            if (response.status === 200) {
                setStatus("Message sent successfully! :)");
                event.target.reset();
            } else {
                setStatus(`Failed to send message. :( Error: ${response.text || 'Unknown error'}`);
            }
        } catch (error) {
            setStatus("An error occurred. :( Please try again.");
        } finally {
            setIsSending(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Victor Springer | Contact</title>
                <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
            </Helmet>

            <header className="max-w-2xl mx-auto text-center">
                <h1 className="text-dracula-pink text-4xl font-semibold mb-4">Contact Me<span className="cursor"></span></h1>
                <p className="text-lg">Feel free to reach out via any of the channels below.</p>
            </header>

            <main className="max-w-2xl mx-auto mt-6">
                <section className="mb-6">
                    <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1">Contact Information</h2>
                    <ul className="list-none mt-4 space-y-3">
                        <li>
                            <span className="font-semibold">LinkedIn:</span>
                            <a
                                href="https://linkedin.com/in/victorspringer"
                                className="text-dracula-cyan underline ml-2 break-words"
                            >
                                linkedin.com/in/victorspringer
                            </a>
                        </li>
                        <li>
                            <span className="font-semibold">GitHub:</span>
                            <a
                                href="https://github.com/victorspringer"
                                className="text-dracula-cyan underline ml-2 break-words"
                            >
                                github.com/victorspringer
                            </a>
                        </li>
                    </ul>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1">Send a Message</h2>
                    <form className="mt-4 space-y-4" onSubmit={handleSubmit}>
                        <label className="block font-semibold mb-1" htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" autoComplete="off" className="w-full p-2 rounded bg-dracula-comment text-dracula-foreground border border-dracula-border focus:outline-none" required />

                        <label className="block font-semibold mb-1" htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" autoComplete="off" className="w-full p-2 rounded bg-dracula-comment text-dracula-foreground border border-dracula-border focus:outline-none" required />

                        <label className="block font-semibold mb-1" htmlFor="message">Message</label>
                        <textarea id="message" name="message" rows="4" className="w-full p-2 rounded bg-dracula-comment text-dracula-foreground border border-dracula-border focus:outline-none" required></textarea>

                        <button type="submit" className="bg-dracula-green text-dracula-background w-[80px] h-[40px] px-4 py-2 font-semibold rounded shadow" disabled={isSending}>
                            {isSending ? (
                                <span className="spinner border-2 border-t-2 border-dracula-background w-4 h-4"></span>
                            ) : (
                                "Send"
                            )}
                        </button>
                        {status && <p className="mt-2 text-dracula-yellow">{status}</p>}
                    </form>
                </section>
            </main>
        </>
    );
};

export default Contact;
