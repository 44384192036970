import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Victor Springer | Not Found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <main className="max-w-4xl mx-auto text-center py-16">
        <section>
          <h1 className="text-dracula-pink text-4xl text-center font-bold mb-6">
            404 - Page Not Found<span className="cursor"></span>
          </h1>
          <p className="text-dracula-comment mb-6">
            Sorry, the page you're looking for doesn't exist. :(
          </p>
        </section>
      </main>
    </>
  );
};

export default NotFound;
