import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import CV from "./pages/CV";
import Blog from "./pages/Blog/Blog";
import Contact from "./pages/Contact";
import BlogPost from "./pages/Blog/BlogPost";
import PostDetailPage from "./pages/Blog/PostDetailPage";
import NotFound from "./pages/NotFound";
import BBBVoter from "./pages/BBBVoter";

const Navbar = () => {
  const location = useLocation();

  const getLinkClass = (path) => {
    return location.pathname === path || location.pathname.startsWith(path + "/")
      ? "text-dracula-green font-bold"
      : "text-dracula-cyan hover:text-dracula-green";
  };

  return (
    <nav className="bg-dracula-currentLine p-4 flex justify-center items-center w-full shadow-lg mb-4">
      <Link to="/" className="flex items-center">
        <img
          src="/logo192.png"
          alt="Victor Springer Logo"
          className="h-10 w-auto mr-4"
        />
      </Link>

      <div className="flex space-x-6">
        <Link to="/" className={getLinkClass("/")}>Home</Link>
        <Link to="/cv" className={getLinkClass("/cv")}>CV</Link>
        <Link to="/blog" className={getLinkClass("/blog")}>Blog</Link>
        <Link to="/contact" className={getLinkClass("/contact")}>Contact</Link>
      </div>
    </nav>
  );
};

const App = () => {
  return (
    <>
      <Helmet>
        <title>Victor Springer | Software Engineer</title>
        <meta
          name="description"
          content="Victor Springer is an experienced software engineer specializing in Golang, TypeScript, microservices, and cloud architecture. Based in Berlin, Germany."
        />
        <meta
          name="keywords"
          content="Victor Springer, Software Engineer, Golang, TypeScript, Microservices, AWS, Cloud, Backend, Full-stack, Tech Blog, CV, Berlin"
        />
        <meta name="author" content="Victor Springer" />
        <meta property="og:title" content="Victor Springer | Software Engineer" />
        <meta property="og:description" content="Experienced software engineer specializing in Golang, TypeScript, microservices, and cloud architecture." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://victorspringer.com" />
        <meta property="og:image" content="https://victorspringer.com/avatar.jpg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Victor Springer | Software Engineer" />
        <meta name="twitter:description" content="Experienced software engineer specializing in Golang, TypeScript, microservices, and cloud architecture." />
        <meta name="twitter:url" content="https://victorspringer.com" />
        <meta name="twitter:image" content="https://victorspringer.com/avatar.jpg" />
      </Helmet>

      <Router>
        <div className="flex flex-col min-h-screen">
          <Navbar />

          <div className="flex-grow p-6 pt-12 bg-dracula-background text-dracula-foreground">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cv" element={<CV />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<PostDetailPage />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog-post" element={<BlogPost />} />
              <Route path="/bbb-voter" element={<BBBVoter />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>

          <footer className="max-w-4xl mx-auto mt-4 text-center border-t border-dracula-comment p-8 flex-shrink-0">
            <p className="text-sm">© {new Date().getFullYear()} Victor Springer. All Rights Reserved.</p>
          </footer>
        </div>
      </Router>
    </>
  );
};

export default App;
