import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBummNJD5pHoSLySzgwTlSETj3M_gVw350",
    authDomain: "victorspringer-page.firebaseapp.com",
    projectId: "victorspringer-page",
    storageBucket: "victorspringer-page.firebasestorage.app",
    messagingSenderId: "735811395266",
    appId: "1:735811395266:web:f9c0db596912861125655b",
    measurementId: "G-MYM1D401M9"
  };
  
  const app = initializeApp(firebaseConfig);
  
  export const analytics = getAnalytics(app);

  export const db = getFirestore(app);

  if (window.location.hostname === "localhost") {
    connectFirestoreEmulator(db, "localhost", 8080);
  }