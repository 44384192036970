import React from "react";
import { Helmet } from "react-helmet";

const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Victor Springer",
    "jobTitle": "Senior Software Engineer",
    "url": "https://linkedin.com/in/victorspringer",
    "image": "https://victorspringer.com/avatar.jpg",
    "sameAs": [
        "https://linkedin.com/in/victorspringer",
        "https://github.com/victorspringer"
    ],
    "address": {
        "@type": "PostalAddress",
        "addressLocality": "Berlin",
        "addressCountry": "Germany"
    },
    "worksFor": [
        {
            "@type": "Organization",
            "name": "Stealth Startup",
            "startDate": "2024-11",
            "endDate": "2025-03",
            "jobTitle": "Senior Software Engineer (Freelance)",
            "description": "Designed and implemented backend and frontend solutions for a new product. Delivered production-grade codebases to establish early-stage product."
        },
        {
            "@type": "Organization",
            "name": "Weavii",
            "startDate": "2023-06",
            "endDate": "2024-03",
            "jobTitle": "Startup CTO (Associate)",
            "description": "Led all technical decisions and software development for the startup as the sole engineer. Developed a professional networking platform for artists."
        },
        {
            "@type": "Organization",
            "name": "OneFootball",
            "startDate": "2023-02",
            "endDate": "2023-07",
            "jobTitle": "Senior Software Engineer",
            "description": "Contributed to video streaming architecture design and scalability for a platform with over 200 million active users."
        },
        {
            "@type": "Organization",
            "name": "home24",
            "startDate": "2019-01",
            "endDate": "2022-07",
            "jobTitle": "Senior Software Engineer",
            "description": "Migrated a legacy PHP monolith to microservices. Implemented GraphQL APIs and backend services."
        },
        {
            "@type": "Organization",
            "name": "americanas s.a.",
            "startDate": "2016-08",
            "endDate": "2018-12",
            "jobTitle": "Software Engineer",
            "description": "Developed e-commerce solutions, optimized system performance, and mentored junior developers."
        }
    ],
    "alumniOf": {
        "@type": "CollegeOrUniversity",
        "name": "Universidade Federal do Estado do Rio de Janeiro (UNIRIO)",
        "degree": "Bachelor of Information Systems",
        "startDate": "2014",
        "endDate": "2018"
    },
    "knowsLanguage": [
        "English (Fluent)",
        "German (A2 Basic)"
    ],
    "award": [
        "1st place, IronBit (internal hackathon) - americanas s.a., 2018",
        "1st place, AWS GameDay - americanas s.a., 2017"
    ],
    "hasCredential": [
        {
            "@type": "EducationalOccupationalCredential",
            "name": "Leadership: Practical Leadership Skills",
            "educationalProgram": "Udemy",
            "dateAwarded": "2024-05"
        },
        {
            "@type": "EducationalOccupationalCredential",
            "name": "Kubernetes for the Absolute Beginners - Hands-on",
            "educationalProgram": "Udemy",
            "dateAwarded": "2024-06"
        }
    ]
};

const skills = [
    { name: "Golang", value: 95 },
    { name: "JavaScript & TypeScript", value: 75 },
    { name: "System Design", value: 90 },
    { name: "Database Optimization", value: 75 },
    { name: "AWS", value: 85 },
    { name: "Mentoring", value: 75 },
];

const SkillsSection = () => {
    return (
        <section className="sm:w-1/2 mx-auto sm:pr-4">
            <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1 mb-4">
                Skills
            </h2>

            <div className="space-y-4">
                {skills.map((skill) => (
                    <div key={skill.name}>
                        <div className="flex justify-between mb-1">
                            <span className="text-sm font-medium font-semibold text-dracula-foreground">{skill.name}</span>
                        </div>
                        <div className="w-full bg-dracula-comment rounded-full h-4">
                            <div
                                className="h-4 bg-dracula-green rounded-full"
                                style={{ width: `${skill.value}%` }}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

const CV = () => {
    return (
        <>
            <Helmet>
                <title>Victor Springer | CV</title>
                <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
            </Helmet>

            <header className="max-w-4xl mx-auto text-center">
                <h1 className="text-dracula-pink text-4xl font-semibold mb-4 text-center">Victor Springer<span className="cursor"></span></h1>
                <p className="text-lg">Berlin, Germany</p>
                <p className="text-lg">
                    <a href="https://linkedin.com/in/victorspringer" className="text-dracula-cyan underline">LinkedIn</a>
                </p>
                <p className="text-lg">
                    <a href="https://github.com/victorspringer" className="text-dracula-cyan underline">GitHub</a>
                </p>
            </header>

            <main className="max-w-4xl mx-auto mt-6">
                <section className="mb-6 sm:mb-0">
                    <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1">Summary</h2>
                    <p className="mt-2">
                        Experienced software engineer with over 10 years of expertise in server-side and client-side technologies,
                        databases, and system architecture. Skilled in agile delivery, high-availability systems, monitoring, and observability.
                        Proven track record in leading technology decisions and delivering efficient, scalable and long-lasting solutions for startups and global platforms.
                    </p>
                </section>
                <div className="sm:flex flex-wrap sm:items-baseline">
                    <SkillsSection />

                    <div className="sm:w-1/2 mx-auto sm:pl-4">
                        <section className="mt-6">
                            <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1">Education</h2>
                            <ul className="list-disc pl-5 mt-2">
                                <li>
                                    <span className="font-semibold">Bachelor of Information Systems</span> - <span className="text-dracula-pink">UNIRIO (Universidade Federal do Estado do Rio de Janeiro)</span>, <span className="text-dracula-yellow">2014 - 2018</span>
                                </li>
                            </ul>
                        </section>

                        <section className="mt-6">
                            <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1">Courses & Certificates</h2>
                            <ul className="list-disc pl-5 mt-2">
                                <li>
                                    <span className="font-semibold">Leadership: Practical Leadership Skills</span> - <span className="text-dracula-pink">Udemy</span>, <span className="text-dracula-yellow">May 2024</span>
                                </li>
                                <li>
                                    <span className="font-semibold">Kubernetes for the Absolute Beginners - Hands-on (Endorsed by Cloud Native Computing Foundation)</span> - <span className="text-dracula-pink">Udemy</span>, <span className="text-dracula-yellow">June 2024</span>
                                </li>
                            </ul>
                        </section>

                        <section className="mt-6">
                            <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1">Languages</h2>
                            <ul className="list-disc pl-5 mt-2">
                                <li>Fluent English (oral, reading, writing)</li>
                                <li>Basic German (A2)</li>
                            </ul>
                        </section>
                    </div>
                </div>

                <section className="mt-6">
                    <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1 mb-6">Awards</h2>
                    <ul className="list-disc pl-5 mt-2">
                        <li>
                            <span className="font-semibold">1st place, IronBit (internal hackathon)</span> - <span className="text-dracula-pink">americanas s.a.</span>, <span className="text-dracula-yellow">2018</span>
                        </li>
                        <li>
                            <span className="font-semibold">1st place, AWS GameDay</span> - <span className="text-dracula-pink">americanas s.a.</span>, <span className="text-dracula-yellow">2017</span>
                        </li>
                    </ul>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl text-dracula-green border-b-2 border-dracula-comment pb-1 mb-6">
                        Professional Experience
                    </h2>
                    <div className="space-y-6">
                        {[
                            {
                                company: "Stealth Startup",
                                period: "November 2024 - March 2025 (5 months)",
                                title: "Senior Software Engineer (Freelance)",
                                contributions: [
                                    "Designed and implemented backend and frontend solutions for a new product.",
                                    "Delivered production-grade codebases to establish early-stage product.",
                                ],
                                tech: [
                                    "Flutter",
                                    "Firebase",
                                    "Firestore",
                                    "Stripe",
                                    "PHP",
                                    "Kubernetes",
                                    "KanBan",
                                    "Git",
                                    "CI/CD",
                                    "Docker",
                                    "Terraform",
                                    "Google Cloud: GKE",
                                    "Google Cloud: GCE",
                                    "Google Cloud: Cloud SQL",
                                    "Google Cloud: GCR",
                                ],
                            },
                            {
                                company: "Weavii",
                                period: "June 2023 - March 2024 (10 months)",
                                title: "Startup CTO (Associate)",
                                contributions: [
                                    "Led all technical decisions and software development for the startup as the sole engineer.",
                                    "Collaborated with stakeholders to align technology solutions with business goals.",
                                    "Landing page: a landing page, written in React, where the users can get information about the product, request access to the platform, contact us and subscribe to the newsletter.",
                                    "MVP: a professional networking platform for artists. The backend is a monolith GraphQL server written in Golang. It retrieves data from Neo4J (graph database) and Redis (for the built-in authentication data). The frontend is written in TypeScript using the Next.js framework for server-side rendering.",

                                ],
                                tech: [
                                    "Golang",
                                    "Neo4J",
                                    "GraphQL",
                                    "Redis",
                                    "Next.js",
                                    "TypeScript",
                                    "React",
                                    "OAuth 2.0",
                                    "KanBan",
                                    "Git",
                                    "Trello",
                                    "Docker",
                                    "Terraform",
                                    "GitHub Actions",
                                    "AWS ECS",
                                    "AWS CloudWatch",
                                    "AWS S3",
                                    "AWS SQS",
                                    "AWS SNS",
                                    "AWS SSM",
                                    "AWS ElastiCache",
                                    "AWS CloudFront",
                                    "AWS Route53",
                                    "MailChimp",
                                ],
                            },
                            {
                                company: "OneFootball",
                                period: "February 2023 - July 2023 (6 months)",
                                title: "Senior Software Engineer",
                                contributions: [
                                    "Contributed to the architecture design for video streaming delivery using Bitmovin.",
                                    "Supported scalability of the platform with over 200 million active users.",
                                    "Watch: Real-time and content management for live football matches. Integrated with a graphic interface built with Retool to support the Streaming Operations team.",
                                    "Clips: Video content ingestion API, integrated with 3rd party streaming providers, for real-time clips for match events and highlights.",
                                    "Content protection (DRM, geo restrictions).",
                                ],
                                tech: [
                                    "Golang",
                                    "Kubernetes",
                                    "Microservices",
                                    "Node.js",
                                    "KanBan",
                                    "Git",
                                    "Jira",
                                    "Continuous Integration",
                                    "Docker",
                                    "Terraform",
                                    "Jenkins",
                                    "GitHub Actions",
                                    "AWS CloudWatch",
                                    "AWS S3",
                                    "AWS RDS",
                                    "AWS SQS",
                                    "AWS SNS",
                                    "AWS SSM",
                                    "AWS ElastiCache",
                                    "Akamai",
                                    "Bitmovin",
                                    "New Relic",
                                    "Retool",
                                ],
                            },
                            {
                                company: "home24",
                                period: "January 2019 - July 2022 (3 years and 6 months)",
                                title: "Senior Software Engineer",
                                contributions: [
                                    "Played a key role in migrating a legacy PHP monolith to a microservices architecture.",
                                    "Assisted in architectural decisions to improve system reliability and scalability.",
                                    "Cosmic: a middleware with caching feature between the CMS (prismic.io) and GraphQL API ",
                                    "GraphQL: Golang implementation of GraphQL API that serves all backend data to the interface clients (web app and native Android/IOS apps).",
                                    "Authentication Service: Authentication server based on the OAuth 2.0 protocol.",
                                    "Reclamation Service: helped the Customer Communication development team to build this backend service that provides the workflow for the customer reclamation process.",
                                    "Common Gateway: Router, cookies / headers / AB testing handler.",
                                    "DevOps / scripts / templates / guidelines / monitoring as part of the Platform & Dev Experience Team (8 people).",
                                    "Implementation of required features into the respective backend services (and GraphQL) in order to integrate Mirakl platform and release the Marketplace business into our product.",
                                    "Mentored junior developers and apprentices.",
                                ],
                                tech: [
                                    "Golang",
                                    "Microservices",
                                    "GraphQL",
                                    "Scrum",
                                    "KanBan",
                                    "Git",
                                    "Jira",
                                    "Continuous Integration",
                                    "Docker",
                                    "AWS EC2",
                                    "AWS ECS",
                                    "AWS CloudFormation",
                                    "AWS CloudWatch",
                                    "AWS S3",
                                    "AWS Lambda",
                                    "AWS StepFunctions",
                                    "AWS DynamoDB",
                                    "AWS SQS",
                                    "AWS SNS",
                                    "AWS SSM",
                                    "AWS ElastiCache",
                                    "AWS RDS",
                                    "CircleCI",
                                    "Terraform",
                                    "Artisan",
                                    "New Relic",
                                    "Prismic",
                                    "Dynamic Yields",
                                    "Akamai",
                                    "Mirakl",
                                ],
                            },
                            {
                                company: "americanas s.a.",
                                period: "August 2016 - December 2018 (2 years and 4 months)",
                                title: "Software Engineer",
                                contributions: [
                                    "Developed e-commerce solutions to optimize performance and conversion rates across multiple brands.",
                                    "Improved system availability through backend enhancements and code optimization.",
                                    "Catalog: the main web application",
                                    "Slug API: router, friendly URLs and SEO",
                                    "Search API",
                                    "Big Data: collection and availability of users navigation data",
                                    "Recommender System (in progress)",
                                    "Mentored junior and intern developers, and taught a Golang workshop.",
                                ],
                                impact: [
                                    "Increase in mobile conversion rate by 10% with the new catalog.",
                                    "Decrease in the website's loading time by 30%, improving the UX, UI, IT environments and the browsing, exploring and search solutions for our customers.",
                                    "New sales record, dominance of internet traffic and increase in the visits share by 6.0 p.p. in the Black Friday 2016 (compared to the previous year).",
                                    "Increase in the market share up to 28.4% in the fourth quarter of 2016.",
                                    "Increase of the GMV by 10.1% (R$ 2.500 to R$ 2.753 millions) in the second quarter of 2017 (compared to the same period of 2016).",
                                    "Three Black Friday events with no downtime in any of the listed developed projects.",
                                ],
                                tech: [
                                    "Golang",
                                    "Node.js",
                                    "React",
                                    "Python",
                                    "Elasticsearch",
                                    "MongoDB",
                                    "Apache TinkerPop",
                                    "Microservices",
                                    "DDD",
                                    "Scrum",
                                    "Git",
                                    "Gitlab",
                                    "Jira",
                                    "Continuous Integration",
                                    "Docker",
                                    "AWS EC2",
                                    "AWS Elastic Beanstalk",
                                    "AWS ECS",
                                    "AWS CloudWatch",
                                    "AWS S3",
                                    "AWS Lambda",
                                    "AWS Step Functions",
                                    "AWS DynamoDB",
                                    "AWS SQS",
                                    "AWS SNS",
                                    "AWS Kinesis",
                                    "AWS Glue",
                                    "AWS Athena",
                                    "AWS Neptune",
                                    "Terraform",
                                    "Ansible",
                                    "Linux",
                                    "New Relic"
                                ],
                            },
                            {
                                company: "LD1",
                                period: "January 2015 - July 2016 (1 year and 6 months)",
                                title: "Web Developer",
                                contributions: [
                                    "SaaS CMMS for a large company in the clinical engineering branch. The backend was written in Java using the Hibernate framework and communicating through SOAP protocol.",
                                    "Mentored intern developers.",

                                ],
                                tech: [
                                    "Java",
                                    "Hibernate",
                                    "SOAP",
                                    "HTML5",
                                    "Google Maps API",
                                    "jQuery",
                                    "CSS3",
                                    "MVC",
                                    "MySQL",
                                    "Eclipse",
                                    "SVN repository",
                                    "Trello",
                                ],
                            },
                        ].map((job) => (
                            <article key={job.company} className="bg-dracula-grey p-4 rounded-lg shadow-md">
                                <h3 className="text-xl font-semibold text-dracula-pink">{job.company}</h3>
                                <p className="text-sm text-dracula-yellow">{job.period}</p>
                                <h4 className="text-lg font-semibold mt-1">{job.title}</h4>
                                <ul className="list-disc pl-5 mt-2">
                                    {job.contributions.map((point, idx) => (
                                        <li key={idx}>{point}</li>
                                    ))}
                                </ul>
                                {job.impact && job.impact.length > 0 && (
                                    <>
                                        <h5 className="text-dracula-orange mt-4">Impact</h5>
                                        <ul className="list-disc pl-5 mt-2">
                                            {job.impact.map((point, idx) => (
                                                <li key={idx}>{point}</li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                                <div className="flex flex-wrap gap-2 mt-4" aria-label="Technology stack used">
                                    {job.tech.map((tech, index) => (
                                        <span
                                            key={index}
                                            className="bg-dracula-purple text-dracula-foreground px-3 py-1 text-xs font-semibold rounded-full">
                                            {tech}
                                        </span>
                                    ))}
                                </div>
                            </article>
                        ))}
                    </div>
                </section>
            </main>
        </>
    );
};

export default CV;
