import React, { useState } from "react";
import { Helmet } from "react-helmet";
import MD5 from "crypto-js/md5";
import { db } from "../../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { Editor } from "@tinymce/tinymce-react";
import slugify from 'slugify';
import "prismjs";
import "prismjs/themes/prism-solarizedlight.min.css";
import "prismjs/components/prism-go.min.js";

const BlogPost = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const correctPassword = "de577c85def9c768517d35b3793744b5";

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (MD5(password).toString() === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title || !content) {
      return;
    }
    setLoading(true);
    setStatus(null);
    try {
      await addDoc(collection(db, "blog"), {
        author: "Victor Springer",
        title,
        content,
        slug: slugify(title, { lower: true }).replace(/:/g, ''),
        timestamp: serverTimestamp(),
      });
      setStatus("Post published successfully! :)");
      setTitle("");
      setContent("");
    } catch (error) {
      setStatus(`Failed to publish post. :( Error: ${error || 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <>
        <Helmet>
          <title>Victor Springer | Restricted Area</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="flex flex-col items-center justify-center bg-dracula-background text-dracula-foreground">
          <header className="text-center">
            <h1 className="text-dracula-pink text-4xl font-semibold mb-4">Restricted Area<span className="cursor"></span></h1>
          </header>
          <h2 className="text-2xl text-dracula-green mb-4">Enter Password</h2>
          <form onSubmit={handlePasswordSubmit} className="space-y-4">
            <input
              required
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 bg-dracula-grey text-dracula-foreground border border-dracula-comment rounded"
            />
            <button
              type="submit"
              className="bg-dracula-green text-dracula-background px-4 py-2 rounded shadow font-semibold"
            >
              Submit
            </button>
          </form>
        </div>
      </>
    );
  }

  const colorPalette = [
    "F8F8F2", "Foreground",
    "6272A4", "Comment",
    "8BE9FD", "Cyan",
    "50FA7B", "Green",
    "FFB86C", "Orange",
    "FF79C6", "Pink",
    "BD93F9", "Purple",
    "FF5555", "Red",
    "F1FA8C", "Yellow",
    "44475A", "Grey",
  ];

  return (
    <>
      <Helmet>
        <title>Victor Springer | Create Blog Post</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="max-w-4xl mx-auto bg-dracula-background text-dracula-foreground">
        <header className="text-center">
          <h1 className="text-dracula-pink text-4xl font-semibold mb-4">Create Blog Post<span className="cursor"></span></h1>
        </header>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            required
            id="title"
            name="title"
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 bg-dracula-grey text-dracula-foreground border border-dracula-comment rounded"
          />
          <Editor
            apiKey="e6boyr9dafjnhdzjxarl4sscbhypmiq2srx76wyv1unaxyub"
            value={content}
            init={{
              height: 400,
              menubar: false,
              plugins: "advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount codesample",
              toolbar:
                "undo redo | formatselect | fontsize bold italic underline forecolor | alignleft aligncenter alignright | bullist numlist | image | codesample | code",
              content_style: `body { background: #282A36; color: #F8F8F2; font-family: 'Fira Code', monospace; font-size: 16px; }
                            .textHighlight { background-color: #F8F8F2; color: #282A36; padding: 1px 4px; border-radius: 4px; }`,
              color_map: colorPalette,
              font_size_formats: "16px 12px 14px 18px 20px 24px 36px",
              codesample_global_prismjs: true,
              codesample_languages: [
                { text: 'Go', value: 'go' },
                { text: 'JavaScript', value: 'javascript' },
                { text: 'HTML', value: 'markup' },
                { text: 'CSS', value: 'css' },
              ],
            }}
            onEditorChange={(newContent) => setContent(newContent)}
          />
          <button
            type="submit"
            className="bg-dracula-green text-dracula-background w-[148px] h-[40px] px-4 py-2 font-semibold rounded shadow"
            disabled={loading}
          >
            {loading ? (
              <span className="spinner border-2 border-t-2 border-dracula-background w-4 h-4"></span>
            ) : (
              "Publish Post"
            )}
          </button>
          {status && <p className="mt-2 text-dracula-yellow">{status}</p>}
        </form>
      </div>
    </>
  );
};

export default BlogPost;
