import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const estimateReadTime = (content) => {
  const wordsPerMinute = 238;
  const wordCount = content.split(/\s+/).length;
  const minutes = Math.ceil(wordCount / wordsPerMinute);
  return minutes <= 1 ? "1 min read" : `${minutes} min read`;
};

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`/api/blog`);
        if (!response.ok) {
          setPosts([]);
          setIsLoading(false);
          return;
        }
        const data = await response.json();
        setPosts(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching post:", error);
        setPosts([]);
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const formatTimestamp = (timestamp) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(timestamp._seconds * 1000).toLocaleDateString("en-US", options);
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Blog",
    "name": "Victor Springer's Tech Blog",
    "author": {
      "@type": "Person",
      "name": "Victor Springer",
      "image": "https://victorspringer.com/avatar.jpg",
    }
  };
  if (posts.length > 0) {
    jsonLd["blogPosts"] = posts.map(post => ({
      "@type": "BlogPosting",
      "headline": post.title,
      "author": {
        "@type": "Person",
        "name": post.author || "Victor Springer",
        "image": "https://victorspringer.com/avatar.jpg",
      },
      "datePublished": post.timestamp ? new Date(post.timestamp._seconds * 1000).toISOString() : "",
      "url": `/blog/${post.slug}`,
      "articleBody": post.content,
      "image": "https://victorspringer.com/avatar.jpg",
    }));
  };

  return (
    <>
      <Helmet>
      <title>Victor Springer | Blog</title>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        <meta name="author" content="Victor Springer" />
      </Helmet>

      <main className="max-w-4xl mx-auto">
        <section className="md:col-span-2">
          <h1 className="text-dracula-pink text-4xl text-center font-semibold mb-6">
            Blog<span className="cursor"></span>
          </h1>

          {isLoading ? (
            <p className="text-center">
              <span className="spinner border-2 border-t-2 border-dracula-green w-8 h-8"></span>
            </p>
          ) : posts.length === 0 ? (
            <p className="text-dracula-comment text-center">No posts available yet.</p>
          ) : (
            posts.map((post) => (
              <article
                key={post.id}
                className="bg-dracula-currentLine p-6 mb-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <a href={`/blog/${post.slug}`}>
                  <h2 className="text-dracula-yellow text-2xl font-semibold">{post.title}</h2>
                  {post.author && (
                    <p className="text-dracula-comment text-sm mt-1">
                      {estimateReadTime(post.content)}
                      <span className="mx-2">·</span>
                      {post.timestamp && (
                        <span>{formatTimestamp(post.timestamp)}</span>
                      )}
                    </p>
                  )}
                  <div
                    className="text-dracula-foreground mt-4 line-clamp-3"
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  />
                </a>
                <a href={`/blog/${post.slug}`} className="text-dracula-cyan text-sm mt-3 inline-block hover:text-dracula-green font-bold">
                  Read more →
                </a>
              </article>
            ))
          )}
        </section>
      </main>
    </>
  );
};

export default Blog;
