import React from "react";
import { Helmet } from "react-helmet";

const jsonLd =  {
  "@context": "https://schema.org",
  "@type": "Person",
  "name": "Victor Springer",
  "image": "https://victorspringer.com/avatar.jpg",
  "jobTitle": "Software Engineer",
  "description": "Victor Springer is a Software Engineer specializing in Golang, TypeScript, microservices, and cloud platforms. He shares professional insights on his blog.",
  "url": "https://victorspringer.com",
  "sameAs": [
    "https://linkedin.com/in/victorspringer",
    "https://github.com/victorspringer"
  ],
  "knowsAbout": [
    "Software Engineering",
    "Golang",
    "TypeScript",
    "Microservices",
    "Cloud Computing",
    "Backend Development",
    "Full-Stack Development"
  ],
  "worksFor": {
    "@type": "Organization",
    "name": "Freelance",
    "url": "https://victorspringer.com"
  },
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://victorspringer.com"
  }
};

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Victor Springer | Software Engineer</title>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      <header className="max-w-4xl mx-auto">
        <img src="avatar.jpg" alt="Victor Springer" className="w-32 h-32 mx-auto mb-4 rounded-full border-2 border-dracula-green" />
        <h1 className="text-dracula-pink text-4xl font-semibold mb-4 text-center">Hello, world!<span className="cursor"></span></h1>
      </header>

      <main className="max-w-4xl mx-auto">
        <section className="text-lg">
          <p>
            Hey, I'm <strong className="text-dracula-green">Victor</strong>! A <strong className="text-dracula-green">Software Engineer</strong> with over a decade of experience in backend and full-stack development.
            I specialize in <strong className="text-dracula-green">Golang, TypeScript, microservices architecture, and cloud platforms</strong>.
            Throughout my career, I've helped startups and global companies build scalable, high-performance and long-lasting systems.
            When I'm not crafting software, you'll find me traveling, playing music (I'm passionate about string instruments), or enjoying sports.
          </p>
        </section>

        <section className="text-lg mt-4">
          <p>
            This website is where I share my <strong className="text-dracula-green">professional experience</strong> and <strong className="text-dracula-green">thoughts on tech</strong>.
            Check out my <a href="/cv" className="text-dracula-purple underline">CV</a> or dive into my <a href="/blog" className="text-dracula-purple underline">Blog</a>, where I write about software engineering, career growth, and the tech industry.
          </p>
        </section>

        <section className="text-lg mt-4">
          <p>
            You can reach me <a href="/contact" className="text-dracula-purple underline" rel="noopener noreferrer">here</a> or check out my projects on <a href="https://github.com/victorspringer" className="text-dracula-purple underline" rel="noopener noreferrer">GitHub</a>.
          </p>
        </section>
      </main>
    </>
  );
};

export default Home;
