import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Prism from "prismjs";
import "prismjs/themes/prism-solarizedlight.min.css";
import "prismjs/components/prism-go.min.js";


const estimateReadTime = (content) => {
  const wordsPerMinute = 238;
  const wordCount = content.split(/\s+/).length;
  const minutes = Math.ceil(wordCount / wordsPerMinute);
  return minutes <= 1 ? "1 min read" : `${minutes} min read`;
};

const PostDetailPage = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/api/blog/${slug}`);
        if (!response.ok) {
          navigate("/not-found");
          return;
        }
        const data = await response.json();
        setPost(data);
      } catch (error) {
        console.error("Error fetching post:", error);
        navigate("/not-found");
      }
    };

    fetchPost();
  }, [slug, navigate]);

  useEffect(() => {
    if (post) {
      Prism.highlightAll();
    }
  }, [post]);

  if (!post) {
    return (
      <p className="text-center">
        <span className="spinner border-2 border-t-2 border-dracula-green w-8 h-8"></span>
      </p>
    );
  }

  const formatTimestamp = (timestamp) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return  new Date(timestamp._seconds * 1000).toLocaleDateString("en-US", options);
  };

  const jsonLd = post
    ? {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": post.title,
      "author": {
        "@type": "Person",
        "name": post.author || "Victor Springer",
        "image": "https://victorspringer.com/avatar.jpg",
      },
      "datePublished": post.timestamp ? new Date(post.timestamp._seconds * 1000).toISOString() : "",
      "url": window.location.href,
      "image": "https://victorspringer.com/avatar.jpg",
      "articleBody": post.content
    }
    : {};

  return (
    <>
      <Helmet>
        <title>Victor Springer | Blog Post</title>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        <meta name="author" content={post.author} />
      </Helmet>

      <main className="max-w-4xl mx-auto">
        <section className="md:col-span-2">
          <h1 className="text-center text-dracula-pink text-4xl font-semibold mb-6">{post.title}<span className="cursor"></span></h1>

          {post.author && (
            <div className="flex items-center text-dracula-comment text-sm mb-6">
              <img
                src={post.authorProfilePicture}
                alt={post.author}
                className="w-11 h-11 rounded-full mr-4"
              />
              <div className="flex flex-col items-start">
                <p className="text-dracula-green">{post.author}</p>
                <p>
                  {estimateReadTime(post.content)}
                  <span className="mx-2">·</span>
                  {post.timestamp && (
                    <span>{formatTimestamp(post.timestamp)}</span>
                  )}
                </p>
              </div>
            </div>
          )}

          <div
            className="text-dracula-foreground mt-4"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />

          <div className="text-center text-sm mt-12">
            <a
              href="/blog"
              className="text-dracula-cyan text-sm mt-3 inline-block hover:text-dracula-green font-bold"
            >
              ← See all posts
            </a>
          </div>
        </section>
      </main>
    </>
  );
};

export default PostDetailPage;
